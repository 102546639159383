import React from 'react'

import Layout from '../components/layout'
import Head from '../components/head'

const Contact = () => {
    return (
        <div>
            <Layout>
                <Head title="Contact" />
                <h1>Contact</h1>
                <p>Contact info here.</p>
                <p>More info can be found <a href="https://www.google.co.uk/" target="_blank" rel="noreferrer">here</a>.</p>
            </Layout>
        </div>
    )
}

export default Contact